












































































































































































































import {Component, Ref, Vue, Prop} from 'vue-property-decorator';
import {ReturnService} from '@/views/order/ReturnService';
import _each from 'lodash/each';
import {formatNumber} from "@/common";
import {ReturnGetByIdResult} from "@/models/return/ReturnGetByIdResult";
import {Money} from 'v-money';
import {Getter} from "vuex-class";
import moment from 'moment';
import {Payment} from '@/models/order/Payment';
import "@/assets/scss/vuexy/extraComponents/autocomplete.scss";
import PaymentTimeline from "@/components/order/PaymentTimeline.vue";
import ReturnPrint from "@/views/order/return-print/ReturnPrint.vue";

@Component({
    components: {
        Money,
        OrderPayment: () => import(/* webpackChunkName: "OrderPayment" */ '@/views/order/order-edit/OrderPayment.vue'),
        PaymentTimeline,
        ReturnPrint
    }
})

export default class ReturnDetail extends Vue {
    @Getter("oidcUser") oidcUser!: any;
    @Ref('orderPaymentRef') orderPaymentRef: any;
    @Ref('returnPrint') returnPrint!: any;

    private returnService: ReturnService;
    paymentTimelineData: any = null;

    constructor() {
        super();
        
        this.returnService = new ReturnService();

        this.listComponent = [
            {
                id: 'orderExchange',
                name: 'Hóa đơn đổi hàng',
            },
        ];
    }

    order: ReturnGetByIdResult = new ReturnGetByIdResult();
    vatUnit: string = "";
    totalMoney: number = 0;
    wasSidebarOpen: any = null;
    listComponent: any = [];
    selectedIndex = 0;

    async beforeMount() {
        this.getOrderById(this.$route.params.id);
    }

    mounted() {
        this.wasSidebarOpen = this.$store.state.reduceButton;
        this.$store.commit('TOGGLE_REDUCE_BUTTON', true);
    }

    beforeDestroy() {
        if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
    }

    formatNumber(value: any) {
        return formatNumber(value);
    }

    openOrderPayment() {
        this.orderPaymentRef.openPopup();
    }

    onPaymentMethodSaveSuccessed(value: number, items: Array<Payment>) {
        _each(items, (item: Payment) => {
            item.money = parseFloat((item.money ? item.money : 0).toString());
        });

        this.order.payments = items;
        this.order.paidPrice = value;
    }

    async getOrderById(orderId: string){
        if (!orderId) {
            return;
        }

        try {
            this.openLoading(true);
            let result = await this.returnService.getById(orderId);

            if (result.code > 0) {
                let orderData = result.data as ReturnGetByIdResult;
                let datetimeFormat = 'DD-MM-YYYY HH:mm';

                if (orderData) {
                    this.paymentTimelineData = {
                        payments: orderData.payments,
                        orderId: orderData.id,
                        orderCode: orderData.code,
                        paidPrice: orderData.paidPrice,
                        totalAmountReturn: orderData.totalAmountReturn,
                        remainPrice: orderData.remainPrice
                    }

                    orderData.orderDate = moment(orderData.orderDate).format(datetimeFormat);
                    orderData.createTime = moment(orderData.createTime).format(datetimeFormat);

                    this.order = orderData;
                }
            }

            this.openLoading(false);
        } catch (error) {
            this.openLoading(false);

            this.$vs.notify({
                title: '',
                text: error.message,
                color: 'danger'
            });
        }
    }

    get chipColor() {
        return (value: any) => {
            return value === 0 ? 'danger' : 'success';
        }
    }

    get statusName() {
        return (value: any) => {
            return value === 0 ? 'Đã hủy' : 'Đã trả';
        }
    }

    onTagClicked(component: any, index: number) {
        this.selectedIndex = index;
        if (component) {
            this.$router.push({
                name: component.id
            });
        }
    }

    print() {
        this.returnPrint.print([this.$route.params.id]);
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading()
        } else {
            this.$vs.loading.close();
        }
    }
}
