import {OrderDetail} from "@/models/order/OrderDetail";
import {DiscountType, OrderType} from "@/constants/Order";
import {Receiver} from "@/models/order/Receiver";
import {Payment} from "@/models/order/Payment";
import {OrderCustomer} from "@/models/order/OrderCustomer";

export class ReturnGetByIdResult {
    id: string;
    tenantId: string;
    code: string;
    orderDate: any;
    discount?: number;
    discountType: DiscountType;
    vAT?: number;
    totalPrice: number;
    totalMoneyBeforeTax: number;
    paidPrice: number;
    totalProduct: number;
    storeId: string;
    storeName: string;
    storeCode: string;
    note: string;
    createTime: string;
    creatorId: string;
    creatorFullName: string;
    creatorPhoneNumber: string;
    status: number;
    fromOrderId: string;
    fromOrderCode: string;
    type: OrderType;
    orderDetails: OrderDetail[];
    receiver: Receiver;
    customer: OrderCustomer;
    payments: Array<Payment>;
    returnCharge: number;
    totalPriceReturn: number;
    totalAmountReturn: number;
    remainPrice: number;
    order: any;
    payAmount: number;

    constructor(){
        this.id = "";
        this.tenantId = '';
        this.code = '';
        this.orderDate = '';
        this.discount = 0;
        this.discountType = DiscountType.Percent;
        this.vAT = 0;
        this.totalPrice = 0;
        this.totalMoneyBeforeTax = 0;
        this.paidPrice = 0;
        this.totalProduct = 0;
        this.storeId = '';
        this.storeName = '';
        this.storeCode = '';
        this.note = '';
        this.createTime = '';
        this.creatorId = '';
        this.creatorFullName = '';
        this.creatorPhoneNumber = '';
        this.status = 0;
        this.fromOrderId = '';
        this.fromOrderCode = '';
        this.type = OrderType.Return;
        this.orderDetails = new Array<OrderDetail>();
        this.receiver = new Receiver("", "");
        this.customer = new OrderCustomer();
        this.payments = new Array<Payment>();
        this.returnCharge = 0;
        this.totalPriceReturn = 0;
        this.totalAmountReturn = 0;
        this.order = null;
        this.payAmount = 0;
    }
}
